(function (wpm, $, undefined) {

	wpm.sendEventPayloadToServer = async payload => {

		const isServerToServerEnabled = () => {

			if (
				wpmDataLayer?.pixels?.facebook?.capi
				|| wpmDataLayer?.pixels?.tiktok?.eapi
			) return true

			return false
		}

		const isServerToServerDisabled = () => {
			return !isServerToServerEnabled()
		}

		const sendPayloadUsingFetch = payload => {

			// Send data to pmw public API with fetch API
			fetch(wpm.root + "pmw/v1/sse/", {
				method     : "POST",
				credentials: "same-origin",
				headers    : {
					"Content-Type": "application/json",
				},
				body       : JSON.stringify(payload),
				keepalive  : true,	// keep connection alive until request is finished
			})
				.then(response => response.json())
				.then(message => {
					if (!message.success) {
						console.error(message)
					}
				})
				.catch(error => {
					console.error(error)
				})

		}

		const sendPayloadUsingNavigatorSendBeacon = data => {
			// https://css-tricks.com/send-an-http-request-on-page-exit/
			navigator.sendBeacon(wpm.root + "pmw/v1/sse/", data)
		}

		try {
			if (isServerToServerDisabled()) return

			if (await wpm.isRestEndpointAvailable()) { // Send data using REST API

				const blob = new Blob([JSON.stringify(payload)], {type: "application/json; charset=UTF-8"})

				// navigator.sendBeacon has a limit of 64kb. Fall back to fetch if the blob is too large.
				if (blob.size < 60000) {
					sendPayloadUsingNavigatorSendBeacon(blob)
				} else {
					sendPayloadUsingFetch(payload)
				}
			} else { // Send data using wp-ajax

				jQuery.ajax(
					{
						type    : "post",
						dataType: "json",
						url     : wpm.ajax_url,
						data    : {
							action: "pmw_server_to_server_event",
							data  : payload,
						},
						success : message => {
							// console.log(message)
						},
						error   : message => {
							console.error(message)
						},
					})
			}

		} catch (e) {
			console.error(e)
		}
	}

	wpm.acrRemoveCookie = () => {

		// Delete the pmw_checkout_order_created cookie
		// This cookie is used to verify that the purchase confirmation page has been reached

		wpm.deleteCookie("pmw_automatic_conversion_recovery")
	}

	wpm.getAcrOrderDetails = async () => {

		// Get the order details from the pmw_automatic_conversion_recovery cookie
		const acrOrderDetails = JSON.parse(wpm.getCookie("pmw_automatic_conversion_recovery"))

		let response

		// Get order details from the server using the REST API if the REST API is available
		if (await wpm.isRestEndpointAvailable()) {
			response = await fetch(wpm.root + "pmw/v1/acr/order/", {
				method     : "POST",
				credentials: "same-origin",
				cache      : "no-cache",
				headers    : {
					"Content-Type": "application/json",
				},
				body       : JSON.stringify({
					order_id : acrOrderDetails.order_id,
					order_key: acrOrderDetails.order_key,
				}),
			})

		} else {
			// Get order details from the server using the wp-ajax API if the REST API is not available using jQuery
			response = await jQuery.ajax({
				type    : "POST",
				dataType: "json",
				url     : wpm.ajax_url,
				data    : {
					action: "get_acr_order_data_ajax",
					data  : {
						order_id : acrOrderDetails.order_id,
						order_key: acrOrderDetails.order_key,
					},
				},
			})

		}

		const responseJson = await response.json()

		if (responseJson.success) {
			console.log("ACR order details retrieved successfully")
		} else {
			console.error("ACR order details could not be retrieved", responseJson)
		}

		wpmDataLayer.products = {...wpmDataLayer.products, ...responseJson.data.products}
		wpmDataLayer.order    = responseJson.data.order
	}

}(window.wpm = window.wpm || {}, jQuery))
